<template>
  <div id="accessories" class="the-accessories" v-if="content?.slides?.length">
    <div class="the-accessories__container">
      <div class="the-accessories__header">{{ content.header }}</div>
      <carousel
        class="the-accessories__carousel"
        :per-page="1"
        :per-page-custom="[[1024, 4], [880, 3], [520, 2]]"
        pagination-active-color="#ff0000"
        pagination-color="#dea4a4"
        :scroll-per-page="false"
        autoplay
        :autoplay-timeout="2000"
        loop
        navigation-enabled
        navigation-next-label=""
        navigation-prev-label=""
      >
        <slide
          v-for="(slide, index) in content.slides"
          :key="index"
          class="the-accessories__slide"
        >
          <img
            class="the-accessories__slide-image"
            v-if="slide.image"
            :src="slide.image"
            alt=""
          >
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
  },
  computed: {
    content() {
      return this.$t('sections.accessories-slider');
    },
  },
};
</script>

<style lang="scss">
.the-accessories {
  padding-bottom: 50px;

  &__container {
    @include container;
  }

  &__header {
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #504243;

    margin-bottom: 32px;
  }

  &__slide {
    &-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
  }

  .VueCarousel {
    &-navigation {
      &-button {
        width: 20px;
        height: 20px;
        border-left: 3px solid #E1E1E1;
        border-bottom: 3px solid #E1E1E1;

        outline: none !important;
      }

      &-prev {
        transform: translate(-100%, -150%) rotate(45deg);
      }
      &-next {
        transform: translate(100%, -150%) rotate(-135deg);
      }
    }
  }

  @include media-bp(mob) {
    &__header {
      font-size: 24px;
      line-height: 28px;
    }
  }
}
</style>
