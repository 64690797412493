<template>
  <div id="how-it-works" class="the-how-it-works qv-section" v-if="content?.steps?.length">
    <div class="the-how-it-works__container qv-section__container">
      <div class="the-how-it-works__header qv-section__header">{{ content.header }}</div>
      <div class="the-how-it-works__list">
        <div
          v-for="(step, index) in content.steps"
          :key="index"
          class="the-how-it-works__step"
        >
          <div class="the-how-it-works__step-container">
            <img
              v-if="step.image"
              :src="step.image" alt=""
              class="the-how-it-works__step-image"
            >

            <div
              v-if="step.text"
              class="the-how-it-works__step-number"
            >
              {{ index + 1 }}
            </div>

            <div v-if="step.text" class="the-how-it-works__step-text"
            >
              {{ step.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$t('sections.how-it-works');
    },
  },
};
</script>

<style lang="scss">
.the-how-it-works {
  padding-bottom: 50px;

  &__list {
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  &__step {
    flex: 1 1 25%;
    max-width: 25%;
    padding: 0 16px;

    &-container {
      display: flex;
      flex-flow: column;
    }

    &-image {
      width: 100%;
      height: 200px;
      object-fit: contain;
      object-position: center;
    }

    &-number {
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 138.19%;

      color: #FF0000;

      &::after {
        content: '.';
      }
    }

    &-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 138.19%;

      color: #000000;
    }
  }

  @include media-bp(tab) {
    &__step {
      flex: 1 1 50%;
      max-width: 50%;
      margin-bottom: 28px;
    }
  }

  @include media-bp(mob) {
    &__list {
      flex-direction: column;
    }

    &__step {
      flex: 0 0 auto;
      max-width: 100%;

      &-image {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
