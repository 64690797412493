<template>
  <app-page-layout
    v-bind="content"
    class="page-rental"
  >
    <template #control class="page-rental__header">
      <a
        class="btn"
        :href="$t('page.rental.button.url')"
        target="_blank"
      >
      <img src="@/assets/img/icon_play.png" alt="play" class="btn__icon">
      <span class="btn__text">
        {{ $t('page.rental.button.text') }}
      </span>
      </a>
    </template>

    <the-how-it-works/>

    <the-calculator/>

    <the-accessories/>

  </app-page-layout>
</template>

<script>
import AppPageLayout from '@/components/app-page-layout';
import TheHowItWorks from '@/components/the-how-it-works';
import TheCalculator from '@/components/the-calculator';
import TheAccessories from '@/components/the-accessories';

export default {
  components: {
    AppPageLayout,
    TheHowItWorks,
    TheCalculator,
    TheAccessories,
  },
  computed: {
    content() {
      return this.$t('page.rental');
    },
  },
};
</script>

<style lang="scss">
.page-rental {

  .app-page-layout__header {
    margin-bottom: 100px;

    @include media-bp(mob) {
      margin-bottom: 12px;
    }
  }
}
</style>
