<template>
  <div id="calculator" class="the-calculator qv-section qv-section--red">
    <div class="the-calculator__header qv-section__header">
      {{ $t('calculator.header') }}
    </div>

    <div class="the-calculator__container qv-section__container">
      <div class="the-calculator__wrapper">
        <div class="the-calculator__title">{{ $t('calculator.title') }}</div>
        <div class="the-calculator__text">{{ $t('calculator.text') }}</div>
        <div class="the-calculator__form">
          <label class="the-calculator__form-input">
            <span class="the-calculator__form-input-label">
              {{ $t('calculator.form.country.label') }}
            </span>
            <select
              class="the-calculator__form-input-control"
              v-model="form.countryId"
            >
              <option
                value=""
                disabled
                selected
              >{{ $t('calculator.form.country.placeholder') }}</option>
              <option
                v-for="(country, id) in countries"
                :key="id"
                :value="id"
              >{{ country }}</option>
            </select>
          </label>
          <label class="the-calculator__form-input">
            <span class="the-calculator__form-input-label">
              {{ $t('calculator.form.days.label') }}
            </span>
            <select
              class="the-calculator__form-input-control"
              v-model="form.days"
            >
              <option
                v-for="(days) in (+$t('calculator.form.days.max') || 21)"
                :key="days"
                :value="days"
              >{{ days }}</option>
            </select>
          </label>
          <label class="the-calculator__form-input">
            <span class="the-calculator__form-input-label">
              {{ $t('calculator.form.devices.label') }}
            </span>
            <input
              class="the-calculator__form-input-control"
              type="number"
              min="1"
              v-model="form.devices"
            />
          </label>
          <div class="the-calculator__form-bottom">
            <div class="the-calculator__form-result">
              {{ $t('calculator.form.total')}}
              <span class="the-calculator__form-result-value">
                {{ totalResult }}
              </span>
            </div>
            <div class="the-calculator__form-buttons">
              <a
                class="btn btn--secondary"
                @click="calculate"
              >{{ $t('calculator.form.button.calculate') }}</a>
              <a
                class="btn btn-secondary"
                href="#"
              >{{ $t('calculator.form.button.order') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="the-calculator__image">
        <img :src="$t('calculator.image')" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: {
        313: 'Austria',
        320: 'Belgium',
        395: 'Croatia',
        354: 'Czech Republic',
        373: 'France',
        355: 'Germany',
        397: 'Hungary',
        406: 'Italy',
        459: 'Netherlands',
        472: 'Poland',
        477: 'Portugal',
        494: 'Slovakia',
        366: 'Spain',
        341: 'Switzerland',
        375: 'United Kingdom',
        523: 'United States',
      },
      form: {
        countryId: '',
        days: +this.$t('calculator.form.days.default') || 1,
        devices: +this.$t('calculator.form.devices.default') || 1,
      },
      result: {
        value: 0,
        currency: '€',
      },
    };
  },
  computed: {
    totalResult() {
      return (this.result.value.toFixed(2) || '0.00') + this.result.currency;
    },
  },
  methods: {
    calculate() {},
  },
};
</script>

<style lang="scss">
.the-calculator {
  padding-bottom: 50px;

  &__container {
    display: flex;
  }

  &__wrapper {
    flex: 1 1 50%;
    max-width: 50%;

    padding-top: 16px;
  }

  &__image {
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 138.19%;
    color: #000000;

    margin-bottom: 16px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.19%;
    color: #000000;
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    margin-bottom: 56px;

    &-input {
      display: flex;
      flex-direction: column;

      margin-bottom: 32px;

      &-label {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #000000;

        margin-bottom: 24px;
      }

      &-control {
        font-size: 18px;
        line-height: 21px;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #000;

        background-color: transparent;

        color: #000000;
        padding: 0 15px 5px 5px;
        height: 35px;

        outline: none;
      }
    }

    &-bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &-result {
      font-weight: 500;
      font-size: 25px;
      line-height: 138.19%;
      color: #000000;

      padding: 24px;
      padding-left: 0;

      display: flex;

      &-value {
        padding-left: 25px;
        font-weight: 700;
        font-size: 28px;
        line-height: 138.19%;
        min-width: 6em;
        color: #000000;
      }
    }

    &-buttons {
      display: flex;
      .btn + .btn {
        margin-left: 24px;
      }
    }
  }

  @include media-bp(tab) {
    &__wrapper {
      flex: 0 0 66%;
      max-width: 66%;
    }

    &__image {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }

  @include media-bp(mob) {
    &__wrapper {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__image {
      display: none;
    }

    &__title {
      font-weight: 500;
      font-size: 22px;
      line-height: 138.19%;

      margin-bottom: 16px;
    }

    &__text {
      font-size: 18px;
      line-height: 138.19%;

      margin-bottom: 16px;
    }

    &__form {
      margin-top: 32px;
    }
  }
}
</style>
